import React from 'react';
import './langauge-menu.css';
import icon from '../../images/downloadmenu/1_download_icon.png';

interface Props {
  imageUrls: string[];
  pdfUrls: string[];
  showDownloadIcon?: boolean;
  isVertical?: boolean;
}

export default function LanguageMenu(props: Props): JSX.Element {
  if (props.imageUrls.length !== props.pdfUrls.length) {
    return <> </>;
  }

  const styleClass = props.isVertical
    ? 'language-vertical-menu'
    : 'language-horizontal-menu';
  return (
    <nav className="language-menu">
      <ul className={styleClass}>
        {props.pdfUrls.map((pdfUrl, index) => (
          <li key={`lang-pdf-${index}`}>
            <a href={pdfUrl} download>
              <img
                className="language-menu__flag"
                src={props.imageUrls[index]}
              />
              {props.showDownloadIcon && (
                <img className="language-menu__download-icon" src={icon} />
              )}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
