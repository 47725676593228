import React from 'react';
import './content-container.css';

interface Props {
  children: JSX.Element[] | JSX.Element;
  alignCenter?: boolean;
}

export default function ContentContainer({
  children,
  alignCenter,
}: Props): JSX.Element {
  return (
    <div className={`main-content ${alignCenter ? 'text-center' : ''}`}>
      {children}
    </div>
  );
}
