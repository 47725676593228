import React, { useEffect, useState } from 'react';
import icon from '../../images/arrow_up.svg';
import './scroll-up.css';

export default function ScrollUp(orops): JSX.Element {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (typeof window !== 'undefined') {
        if (!showScroll && window.pageYOffset > 400) {
          setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
          setShowScroll(false);
        }
      }
    };

    if (typeof document !== 'undefined') {
      document.addEventListener('scroll', checkScrollTop, {
        passive: true,
      });
    }

    return () => {
      if (typeof document !== 'undefined') {
        // clean up the event handler when the component unmounts
        document.removeEventListener('scroll', checkScrollTop);
      }
    };
  }, [showScroll]);

  const scrollTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div
      onClick={() => scrollTop()}
      className={'scroll-up ' + (showScroll ? '' : 'scroll-up-hidden')}
    >
      <div className="scroll-up-content">
        <img className="scroll-up__arrow-up-icon" src={icon} />
        <p>
          Zurück zum <br /> Seitenanfang
        </p>
      </div>
    </div>
  );
}
