import React, { useEffect, useState } from 'react';
import './der-stifter.css';
import BasicStructure from '../components/basic-structure/basic-structure';
import erichFischer1993 from '../images/der-stifter/erich-fischer-1993.jpg';
import erichFischer2003 from '../images/der-stifter/erich-fischer-2003.jpg';
import erichFischer2013 from '../images/der-stifter/erich-fischer-2013.jpg';
//import stiftervideo from '../images/der-stifter/Stifter-Video_2017.png';
import '../media-queries/der-stifter-media-queries.css';
import { useStaticQuery, graphql } from 'gatsby';
import { DieStiftungDownloadQuery } from '../../graphql-types';
import ContentContainer from '../components/content-container/content-container';
import Sidebar from '../components/sidebar/sidebar';
import Header from '../components/header/header';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Menubar from '../components/menu/menubar';
import SideLanguageMenu from '../components/language-menu/side-language-menu';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import ScrollUp from '../components/scroll-up/scroll-up';
import Footer from '../components/footer/footer';
import { consentContext } from '../context/ConsentContext';

function DerStifter(): JSX.Element {
  const download = useStaticQuery<DieStiftungDownloadQuery>(
    graphql`
      query DerStifterDownloadQuery {
        imageURL: allFile(
          filter: {extension: {eq: "png"}, name: {regex: "/^\\d+_der/"}},
          sort: {fields: name}
          ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        pdfURL: allFile(
          filter: {extension: {eq: "pdf"}, name: {regex: "/^\\d+_der/"}},
          sort: {fields: name}
          ) {
            edges {
              node {
              publicURL
              }
            }
          }
      }
`,
  );

  const imageURLs = download.imageURL.edges.map(
    (file) => file.node.publicURL ?? '',
  );

  const pdfUrls = download.pdfURL.edges.map((file) => {
    return file.node.publicURL ?? '';
  });

  return (
    <>
      <consentContext.Consumer>
        {(context: any) => (
          <BasicStructure>
            <TitleMetaTags title="Der Stifter" />
            <Header>
              <Menubar styleClass="main-menu" />
              <BurgerMenu>
                {imageURLs && pdfUrls && (
                  <SideLanguageMenu
                    title={'Stifterportrait'}
                    imageUrls={imageURLs}
                    pdfUrls={pdfUrls}
                  />
                )}
              </BurgerMenu>
            </Header>
            {imageURLs && pdfUrls && (
              <Sidebar>
                <div className="stifter_sidebar">
                  <SideLanguageMenu
                    title={'Stifterportrait'}
                    imageUrls={imageURLs}
                    pdfUrls={pdfUrls}
                  />
                  <ScrollUp />
                </div>
              </Sidebar>
            )}
            <ContentContainer>
              <div className="der-stifter__container">
                <h1 className="stifter_portrait_title">Stifterportrait</h1>

                <div className="der-stifter__box">
                  <img
                    className="der-stifter__bild-erich-fischer-1993"
                    src={erichFischer1993}
                    alt="Erich Fischer 1993"
                  />
                  <div>
                    <p className="der-stifter__box-text">
                      Stiftungen gibt’s viele. Und entsprechend viele Stifter.
                      Einer davon, Erich Fischer, 1938 im Sudetenland (Mähren)
                      geboren, bin ich. Bevor ich im Jahre 1995 diese Stiftung
                      gründete, war ich 26 Jahre lang Eigentümer und leitender
                      Geschäftsführer eines europaweit tätigen
                      Vertriebsunternehmens der Halbleiter Branche mit
                      dreihundert Mitarbeitern (EBV).
                    </p>

                    <p className="der-stifter__box-text">
                      Geprägt von den Entbehrungen der Nachkriegszeit und der
                      Beschäftigung mit gesellschaftskritischen Autoren von
                      Dostojewski bis Sartre, war ich schon früh zu der Einsicht
                      gelangt, daß es sowohl im Kommunismus als auch im
                      Kapitalismus extrem ungerecht zugeht, weil die, die
                      wirklich arbeiten, mit verlogenen Parolen wie
                      Vermögensbildung und Mitbestimmung abgespeist und von
                      Unternehmern, Managern und „Vater“ Staat nach Strich und
                      Faden ausgebeutet werden.
                    </p>

                    <p className="der-stifter__text">
                      Das wollte ich in meinem eigenen Unternehmen anders
                      machen. Mir schwebte eine zivilisierte, an sozialen –
                      nicht sozialistischen – Prinzipien orientierte
                      Unternehmensführung vor mit fairer Gewinnbeteiligung und
                      echter Mitbestimmung aller Mitarbeiter. Dieses anfangs
                      belächelte, später beargwöhnte Konzept hat – zu meiner
                      eigenen Verwunderung – zum Vorteil aller bestens
                      funktioniert und so war es für mich ganz
                      selbstverständlich, meine bewährten Mitarbeiter –
                      insgesamt 131 Damen und Herren – mit rund einem Drittel am
                      Unternehmen zu beteiligen.
                    </p>
                  </div>
                </div>

                <div className="der-stifter__box">
                  <img
                    className="der-stifter__bild-erich-fischer-2003"
                    src={erichFischer2003}
                    alt="Erich Fischer 2003"
                  />
                  <div>
                    <p className="der-stifter__box-text">
                      Ein weiteres Drittel meiner Geschäftsanteile schenkte ich
                      der von mir gegründeten
                      <a className="der-stifter__text-bold">
                        &nbsp;Internationalen Stiftung zur Foerderung von Kultur
                        und Zivilisation&nbsp;
                      </a>
                      als Grundstockvermögen, aus dessen Erträgen sie
                      hauptsächlich ihre gemeinnützigen Vorhaben finanziert. Da
                      mein Vermögen größtenteils in meinem Unternehmen
                      investiert war, kann man sagen, daß ich annähernd zwei
                      Drittel verschenkt habe. Das ist in meinen Augen nicht
                      mehr als recht und billig, denn der klassische
                      Kapitalismus, der dazu geführt hat, daß viel zu wenige
                      viel zu viel und viel zu viele viel zu wenig besitzen, hat
                      wie zuvor der Kommunismus endgültig abgewirtschaftet. Das
                      beweisen Börsencrash, Wirtschaftsflaute,
                      Massenarbeitslosigkeit und gigantische Staatsverschuldung.
                      So weit wäre es nicht gekommen, wenn sich „Vater“ Staat
                      nicht zu einem Selbstbedienungsladen für die, die drin
                      sitzen entwickelt hätte, wie der damalige Bundespräsident
                      Gustav Heinemann schon 1974 befürchtete und § 14 Ab. 2 des
                      Grundgesetzes ernst genommen würde&nbsp;
                      <a className="der-stifter__text-bold">
                        „Eigentum verpflichtet, sein Gebrauch soll zugleich dem
                        Wohl der Allgemeinheit dienen“.
                      </a>
                    </p>
                    <p className="der-stifter__text">
                      Wer diese Zeilen als unzeitgemäßen Aufruf zum Klassenkampf
                      auffaßt, mag das tun, sie sind jedoch auch als
                      Stifter-Porträt geeignet, denn sie verdeutlichen meine
                      Grundeinstellung ohne die diese Stiftung nicht zustande
                      gekommen wäre. Welche Stiftungszwecke ich verwirklichen
                      wollte, habe ich in der Stiftungssatzung verankert und im
                      Zusammenhang mit den einzelnen Projekten näher erläutert.
                      Die Frage, warum es gerade diese sind – es könnten in der
                      Tat auch andere sein – läßt sich nicht mit ein paar Sätzen
                      beantworten. Sie haben aber bei aller Unterschiedlichkeit
                      etwas gemeinsam, gewissermaßen die gleiche Tonart, die
                      sich sehr gut mit einer Zeile aus einem Gedicht von
                      Bertolt Brecht beschreiben läßt&nbsp;
                      <a className="der-stifter__text-bold">
                        „denn man sieht nur die im Lichte, die im Dunklen sieht
                        man nicht.“
                      </a>
                    </p>
                  </div>
                </div>

                <div className="der-stifter__box">
                  <img
                    className="der-stifter__bild-erich-fischer-2013"
                    src={erichFischer2013}
                    alt="Erich Fischer 2013"
                  />
                  <div>
                    <p className="der-stifter__box-text">
                      Das gilt zuallererst für das wirklich menschenfreundliche
                      Seniorenprojekt MUSIK AM NACHMITTAG, aber auch für die
                      unter dem Begriff AMNESTY NATIONAL zuammengefaßten
                      Aktivitäten für Gefangene, die ich initiiert habe, weil
                      ich überzeugt bin, daß das heutige Strafrecht immer noch
                      in erster Linie auf dem alttestamentarischen
                      Sanktionsprinzip (Auge um Auge) beruht und nicht nur
                      höchst unzivilisiert ist, sondern auch unwirksam, wie die
                      hohen Kriminalitäts- und Rückfallraten zeigen. Und auch im
                      Bereich Kunst und Kultur geht es mir um die Förderung von
                      eher unbekannten Kompositionen und Bühnenwerken, sowie und
                      vor allem um die Förderung sozial und damit oft auch
                      kulturell benachteiligten Menschen und weniger um die
                      sogenannten Begabten, die sowieso im Lichte der
                      Öffentlichkeit stehen und von dieser auf vielfältige Weise
                      gefördert werden.
                    </p>
                    <p className="der-stifter__text">
                      Auf die Frage eines ehemaligen Mitarbeiters, wie es mir
                      als Stifter und Stiftungsvorstand ergehe, antwortete ich
                      „gut, aber ich hätte nicht gedacht, daß Geld ausgeben
                      genausoviel Mühe und Ärger macht wie Geld verdienen.“
                    </p>
                    <p className="der-stifter__text">© 2004, Erich Fischer</p>

                    <p className="der-stifter__text">
                      Last but not least möchte ich den unzähligen Menschen, die
                      ehrenamtlich an den Stiftungsprojekten mitarbeiten, meinen
                      Dank und meine Hochachtung aussprechen.
                    </p>

                    {context.cookieConsent ? (
                      <div
                        className="der-stifter__video-box"
                        key={'video-box-der-stifter'}
                      >
                        <iframe
                          className="der-stifter__video"
                          src="https://www.youtube-nocookie.com/embed/uMXzOtevqvc?rel=0&showinfo=0"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />

                        {/* <a href="https://youtu.be/uMXzOtevqvc">
                          <img
                            className="der-stifter__stiftervideo"
                            src={stiftervideo}
                            alt="Stiftervideo"
                          />
                        </a> */}
                      </div>
                    ) : (
                      <div
                        className={'youtube-no-consent'}
                        key={'video-box-der-stifter-no-consent'}
                      >
                        <p>
                          Bitte die Cookie-Einstellungen anpassen und
                          <b> Externe Medien </b>
                          zulassen, um diesen Inhalt sehen zu können.
                        </p>
                        <button
                          className="youtube-no-consent-button"
                          onClick={context.showModal}
                        >
                          Cookie Einstellungen
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ContentContainer>
            <Footer />
          </BasicStructure>
        )}
      </consentContext.Consumer>
    </>
  );
}

export default DerStifter;
