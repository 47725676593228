import LanguageMenu from './language-menu';
import React from 'react';
import './side-language-menu.css';

export interface SideLanguageMenuProps {
  title: string;
  imageUrls: string[];
  pdfUrls: string[];
}

export default function SideLanguageMenu({
  title,
  imageUrls,
  pdfUrls,
}: SideLanguageMenuProps): JSX.Element {
  return (
    <div className="side-language-menu">
      <h3 className="side-language-menu__title">Flyer &quot;{title}&quot;</h3>
      <h4 className="side-language-menu__subtitle">PDF download</h4>
      <LanguageMenu
        imageUrls={imageUrls}
        pdfUrls={pdfUrls}
        showDownloadIcon={true}
        isVertical={true}
      />
    </div>
  );
}
